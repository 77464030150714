// Pages that can only be visited when user is authenticated

import UploadSuccess from '../components/forms/uploads/UploadsSuccess';
import {
    BioDataDetails,
    Dashboard,
    Medicals,
    MedicalsComplete,
    SportsRegistration,
    Assignments,
    Library,
    LibraryComplete,
    AccommodationRegistration,
    Results,
    AssignmentOverview,
    Timetable,
    Communications,
    Message,
    Fees,
    PaymentHistory,
    Invoice,
    Receipt,
    Summary,
    CourseRegistration,
    AccommodationInfo,
    Courses,
    CourseOverview,
    SportsInformation,
    AssignmentUpload,
    AssignmentSubmit,
    Discussion,
    DiscussionThread,
    Exeat,
    CreatNewExeat,
    ExeatDetails,
    ViewPdfPage,
} from '../views';
import Handbook from '../views/handbook';
import HandbookDetails from '../views/handbook/handbookDetails';

export const protectedRoutes = [
    {
        path: '/',
        element: '',
    },
    {
        path: '/dashboard',
        element: <Dashboard />,
    },
    {
        path: '/dashboard/biodata-registration',
        element: <BioDataDetails />,
    },
    {
        path: '/dashboard/sports-reg',
        element: <SportsRegistration />,
    },
    {
        path: '/dashboard/accomodation-reg/',
        element: <AccommodationRegistration />,
    },
    {
        path: '/dashboard/medicals-reg',
        element: <Medicals />,
    },
    {
        path: '/dashboard/medicals-report',
        element: <MedicalsComplete />,
    },
    {
        path: '/assignments/',
        element: <Assignments />,
    },
    {
        path: '/dashboard/library-reg',
        element: <Library />,
    },
    {
        path: '/dashboard/library-report',
        element: <LibraryComplete />,
    },
    {
        path: '/results',
        element: <Results />,
    },
    {
        path: '/discussion',
        element: <Discussion />,
    },
    {
        path: '/discussion/:id',
        element: <DiscussionThread />,
    },
    {
        path: '/payments',
        element: <Fees />,
    },
    {
        path: '/courses',
        element: <Courses />,
    },
    {
        path: '/courses/:id',
        element: <CourseOverview />,
    },
    {
        path: '/courses/course-reg',
        element: <CourseRegistration />,
    },
    {
        path: '/assignment/details/:id',
        element: <AssignmentOverview />,
    },
    {
        path: '/assignments/submit/:id',
        element: <AssignmentSubmit />,
    },

    {
        path: '/timetable',
        element: <Timetable />,
    },
    {
        path: '/communications',
        element: <Communications />,
    },
    {
        path: '/message/notice/:id',
        element: <Message />,
    },
    {
        path: '/message/bulletin/:id',
        element: <Message />,
    },
    {
        path: '/payments/payment-history',
        element: <PaymentHistory />,
    },
    {
        path: '/payments/invoice',
        element: <Invoice />,
    },
    {
        path: '/payments/receipt/:id',
        element: <Receipt />,
    },
    {
        path: '/payments/summary',
        element: <Summary />,
    },
    {
        path: '/accommodation/info',
        element: <AccommodationInfo />,
    },
    {
        path: '/upload/success',
        element: <UploadSuccess />,
    },
    {
        path: '/sports/info',
        element: <SportsInformation />,
    },
    {
        path: '/assignments/upload/:id',
        element: <AssignmentUpload />,
    },
    {
        path: '/exeat',
        element: <Exeat />,
    },
    {
        path: '/exeat/create-exeat',
        element: <CreatNewExeat />,
    },
    {
        path: '/exeat/:id',
        element: <ExeatDetails />,
    },
    {
        path: '/discussion/view/:id',
        element: <ViewPdfPage />,
    },
    {
        path: '/handbook',
        element: <Handbook />,
    },
    {
        path: '/handbook/:id',
        element: <HandbookDetails />,
    },
];
