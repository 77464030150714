import { combineReducers } from '@reduxjs/toolkit';

import assignmentReducer from './assignments/slice';
import authReducer from './auth/slice';
import bioDataReducer from './bioData/slice';
import communicationReducer from './communication/slice';
import coursesReducer from './courses/slice';
import discussionReducer from './discussion/slice';
import exeatReducer from './exeat/slice';
import handbookReducer from './handbook/slice';
import libraryReducer from './library/slice';
import medicalReducer from './medical/slice';
import paymentReducer from './payment/slice';
import sessionReducer from './session/slice';
import sportReducer from './sport/slice';
import timetableReducer from './timetable/slice';
import studentReducer from './user/slice';
export const combinedReducer = combineReducers({
    auth: authReducer,
    student: studentReducer,
    courses: coursesReducer,
    discussion: discussionReducer,
    assignment: assignmentReducer,
    medical: medicalReducer,
    sport: sportReducer,
    library: libraryReducer,
    payment: paymentReducer,
    timetable: timetableReducer,
    session: sessionReducer,
    biodata: bioDataReducer,
    communication: communicationReducer,
    exeat: exeatReducer,
    handbook: handbookReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }
    return combinedReducer(state, action);
};
