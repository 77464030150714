import React, { useState } from 'react';

import { ReactComponent as Dropdown2 } from '../../assets/icons/arrow-down2.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-icon.svg';
import { FlexCentredRow, FlexRowSpaceBetween, RelativeContainer } from '../../containers/ScreenContainers';
import { Button } from '../buttons';
// import { AllFilter, FilterPopup } from '../filter-select';
import SearchInput from '../inputs/search-input';
import ActionsPopup from '../popups/actions-popup';
import TableSettings from '../table-settings';

const TableUtils = ({
    onSearchChange,
    searchValue,
    onSearchClose,
    showClear,
    filterItems,
    handleReset,
    openFilterPop,
    isFilterPopLoading,
    closeFilterPop,
    filterPopItems,
    onApplySettings,
    searchable,
    showFilter,
    openFilter,
    closeFilter,
    tableUtilsChildren,
    onSessionFilterClick,
    openSessionFilter,
    closeSessionFilter,
    sessionItems,
    sessionPlaceHolder,
    sessionValue,
    semesterItems,
    onSemesterFilterClick,
    openSemesterFilter,
    closeSemesterFilter,
    semesterValue,
    semesterPlaceHolder,
    showTableSettings,
}) => {
    const [showSettings, setShowSettings] = useState(false);
    return (
        <div className="p-[16px]">
            <FlexRowSpaceBetween>
                <FlexCentredRow className="gap-x-[16px]">
                    {sessionItems && (
                        <RelativeContainer>
                            <Button color="#6B7280" border="1px solid #9CA3AF" onClick={onSessionFilterClick}>
                                {sessionValue || sessionPlaceHolder || 'Session'}
                                <Dropdown2 className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup open={openSessionFilter} close={closeSessionFilter} items={sessionItems} />
                        </RelativeContainer>
                    )}
                    {semesterItems && (
                        <RelativeContainer>
                            <Button color="#6B7280" border="1px solid #9CA3AF" onClick={onSemesterFilterClick}>
                                {semesterValue || semesterPlaceHolder || 'Semester'}
                                <Dropdown2 className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup open={openSemesterFilter} close={closeSemesterFilter} items={semesterItems} />
                        </RelativeContainer>
                    )}
                    {searchable && (
                        <SearchInput
                            width={'100%'}
                            withRadius
                            onChange={onSearchChange}
                            value={searchValue}
                            onClose={onSearchClose}
                        />
                    )}
                </FlexCentredRow>
                <FlexCentredRow className="gap-x-[16px]">
                    {/* {showFilter && (
                        <RelativeContainer>
                            <AllFilter
                                open={openFilter}
                                close={closeFilter}
                                showClear={showClear}
                                items={filterItems}
                                handleReset={handleReset}
                            />
                            <FilterPopup
                                open={openFilterPop}
                                isLoading={isFilterPopLoading}
                                close={closeFilterPop}
                                items={filterPopItems}
                            />
                        </RelativeContainer>
                    )} */}
                    {tableUtilsChildren}
                    {showTableSettings && (
                        <SettingsIcon className="cursor-pointer" onClick={() => setShowSettings(!showSettings)} />
                    )}
                </FlexCentredRow>
            </FlexRowSpaceBetween>
            <TableSettings
                show={showSettings}
                close={() => setShowSettings(false)}
                onApplySettings={(val) => {
                    onApplySettings(val);
                    setShowSettings(false);
                }}
            />
        </div>
    );
};

export default TableUtils;
