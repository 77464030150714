import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus, Table } from '@belrald_hq/belrald-ui';

import { GoBack } from '../../components/go-back';
import TableUtils from '../../components/table-utils';
import { PageTitle } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, FlexColumn } from '../../containers/ScreenContainers';

const PageLayout = ({
    backTitle = '',
    backSubtitle = '',
    backLink = '',
    pageTitle = '',
    loading,
    loader,
    noItemView,
    fields,
    data,
    onRowClick,
    showTableUtils = true,
    onSearchChange,
    searchValue,
    onSearchClose,
    showClear,
    filterItems,
    handleFilterReset,
    filterPopItems,
    searchable = true,
    showFilter = false,
    noMargin,
    children,
    openFilter,
    closeFilter,
    openFilterPop,
    closeFilterPop,
    tableUtilsChildren,
    titleAddendum,
    isFilterPopLoading,
    filterCategories,
    actionProp,
    isActionable,
    isCollapsible,
    actionType,
    openCollapseId,
    handleAction,
    eventData,
    onSessionFilterClick,
    openSessionFilter,
    closeSessionFilter,
    sessionItems,
    sessionPlaceHolder,
    onCopyClick,
    sessionValue,
    onSemesterFilterClick,
    openSemesterFilter,
    closeSemesterFilter,
    semesterItems,
    semesterPlaceHolder,
    semesterValue,
    showTableSettings = false,
    isResponsive = false,
    actionPosition,
}) => {
    const [tableSettings, setTableSettings] = useState(null);
    const numOfPages = tableSettings?.numOfPages || 10;
    const totalPages = Math.ceil(data?.length / numOfPages);
    const [currentPage, setCurrentPage] = useState('1');
    const savedSettings = JSON.parse(localStorage.getItem('tableSettings'));

    useEffect(() => {
        // TODO: fix
        // commented out because table always navigates to page 1 on changes on other pages
        // setCurrentPage('1');
        //eslint-disable-next-line
    }, [data, searchValue, onSearchChange, onSearchClose]);

    const onApplySettings = (value) => {
        const { numOfPages, stripedRow } = value || {};
        const settingsToSave = { numOfPages, stripedRow };
        setTableSettings(value);
        setCurrentPage('1');
        localStorage.setItem('tableSettings', JSON.stringify(settingsToSave));
    };

    useEffect(() => {
        if (!tableSettings && savedSettings) {
            setTableSettings(savedSettings);
        }
    }, [tableSettings, savedSettings]);

    return (
        <div className="z-1">
            {backTitle && (
                <FlexColumn className="mb-0 pt-0">
                    <GoBack title={backTitle} backLink={backLink} subTitle={backSubtitle} />
                </FlexColumn>
            )}
            <FlexRowSpaceBetween className="mt-0">
                <FlexCentredRow className="mt-0">
                    <PageTitle align="left">{pageTitle}</PageTitle>
                    {titleAddendum}
                </FlexCentredRow>
            </FlexRowSpaceBetween>
            {fields && (
                <div className={`${noMargin ? '' : 'mt-[3.2rem]'} shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]`}>
                    {showTableUtils && (
                        <TableUtils
                            onApplySettings={onApplySettings}
                            searchValue={searchValue}
                            onSearchChange={onSearchChange}
                            onSearchClose={onSearchClose}
                            searchable={searchable}
                            showFilter={showFilter}
                            showClear={showClear}
                            filterItems={filterItems}
                            openFilterPop={openFilterPop}
                            closeFilterPop={closeFilterPop}
                            filterPopItems={filterPopItems}
                            handleReset={handleFilterReset}
                            openFilter={openFilter}
                            closeFilter={closeFilter}
                            tableUtilsChildren={tableUtilsChildren}
                            isFilterPopLoading={isFilterPopLoading}
                            onSessionFilterClick={onSessionFilterClick}
                            openSessionFilter={openSessionFilter}
                            closeSessionFilter={closeSessionFilter}
                            sessionItems={sessionItems}
                            sessionPlaceHolder={sessionPlaceHolder}
                            sessionValue={sessionValue}
                            onSemesterFilterClick={onSemesterFilterClick}
                            openSemesterFilter={openSemesterFilter}
                            closeSemesterFilter={closeSemesterFilter}
                            semesterItems={semesterItems}
                            semesterPlaceHolder={semesterPlaceHolder}
                            semesterValue={semesterValue}
                            showTableSettings={showTableSettings}
                        />
                    )}
                    {filterCategories}
                    <Table
                        columns={fields}
                        data={data || []}
                        width={'100%'}
                        onRowClick={onRowClick}
                        loading={loading}
                        loader={loader}
                        noItemView={noItemView}
                        striped={tableSettings?.stripedRow}
                        stickyTable={
                            tableSettings?.stickyColumns === 0 || tableSettings?.stickyColumns === 1 ? true : false
                        }
                        tableNumber={tableSettings?.stickyColumns + 1}
                        currentPage={currentPage - 1}
                        pageSize={numOfPages}
                        isActionable={isActionable}
                        isCollapsible={isCollapsible}
                        actionProp={actionProp}
                        actionType={actionType}
                        openCollapseId={openCollapseId}
                        handleAction={handleAction}
                        eventData={eventData}
                        onCopyClick={onCopyClick}
                        isResponsive={isResponsive}
                        actionPosition={actionPosition}
                    />

                    {data?.length > 0 && (
                        <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                            <PaginationStatus
                                currentPage={currentPage}
                                itemsPerPage={numOfPages}
                                totalItems={data?.length}
                            />
                            <Pagination
                                pageRangeDisplayed={3}
                                totalPages={totalPages}
                                onPageChange={(nextPage) => {
                                    setCurrentPage(nextPage);
                                }}
                                currentPage={currentPage}
                            />
                        </FlexRowSpaceBetween>
                    )}
                    {children}
                </div>
            )}
        </div>
    );
};

export default PageLayout;
