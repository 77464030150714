import { ReactComponent as CommsIcon } from '../assets/icons/comms-icon.svg';
import { ReactComponent as CoursesIcon } from '../assets/icons/course-icon.svg';
import { ReactComponent as DiscussionIcon } from '../assets/icons/disc-icon.svg';
import { ReactComponent as ExeatIcon } from '../assets/icons/exeat.svg';
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as LibraryIcon } from '../assets/icons/libraryIcon.svg';
import { ReactComponent as PaymentIcon } from '../assets/icons/payment-icon.svg';
import { ReactComponent as ResultIcon } from '../assets/icons/result-icon.svg';
import { ReactComponent as DashboardIcon } from '../assets/icons/sb-dashboard.svg';
import { ReactComponent as TimetableIcon } from '../assets/icons/timetableIcon.svg';

export const navLinks = [
    {
        title: 'Dashboard',
        to: '/dashboard',
        active: <DashboardIcon fill="#1F2937" />,
        src: <DashboardIcon fill="#F3F4F6" />,
    },
    {
        title: 'Discussions',
        to: '/discussion',
        src: <DiscussionIcon fill="#F3F4F6" />,
        active: <DiscussionIcon fill="#1F2937" />,
    },
    {
        title: 'Assignments',
        to: '/assignments',
        src: <LibraryIcon fill="#F3F4F6" />,
        active: <LibraryIcon fill="#1F2937" />,
    },
    {
        title: 'Timetable',
        to: '/timetable',
        src: <TimetableIcon fill="#F3F4F6" />,
        active: <TimetableIcon fill="#1F2937" />,
    },
    {
        title: 'Communications',
        to: '/communications',
        src: <CommsIcon fill="#F3F4F6" />,
        active: <CommsIcon fill="#1F2937" />,
    },
    {
        title: 'Courses',
        to: '/courses',
        src: <CoursesIcon fill="#F3F4F6" />,
        active: <CoursesIcon fill="#1F2937" />,
    },
    {
        title: 'Results',
        to: '/results',
        src: <ResultIcon fill="#F3F4F6" />,
        active: <ResultIcon fill="#1F2937" />,
    },
    {
        title: 'Payments',
        to: '/payments',
        src: <PaymentIcon fill="#F3F4F6" />,
        active: <PaymentIcon fill="#1F2937" />,
    },
    {
        title: 'Handbook',
        to: '/handbook',
        src: <HomeIcon fill="#F3F4F6" />,
        active: <HomeIcon fill="#1F2937" />,
    },
    process.env.REACT_APP_ENV !== 'production'
        ? {
              title: 'Exeat',
              to: '/exeat',
              src: <ExeatIcon fill="#F3F4F6" />,
              active: <ExeatIcon fill="#1F2937" />,
          }
        : null,
];
