import React from 'react';

const Checkbox = ({ label = '', id, value, checked, onChange, className, labelClassName, ...props }) => {
    return (
        <div className={className || 'ml-6'} onClick={onChange}>
            <div className="relative flex gap-[1.81rem] items-center" onClick={onChange}>
                <input
                    type="checkbox"
                    id={id}
                    name={id}
                    value={value}
                    className="opacity-0 absolute h-5 w-5 cursor-pointer"
                    onChange={onChange}
                    {...props}
                />
                <div
                    className={`${
                        checked ? 'bg-[#6366F1]' : 'border'
                    }  rounded-[4px] border-[#D1D5DB] w-[20px] h-[20px] flex flex-shrink-0 justify-center items-center mr-2`}
                >
                    {checked && (
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.7163 0.582543C11.947 0.794019 11.9626 1.15247 11.7512 1.38317L4.41782 9.38317C4.31338 9.49711 4.16693 9.56344 4.01241 9.5668C3.85788 9.57015 3.70869 9.51025 3.5994 9.40096L0.26607 6.06762C0.0447734 5.84633 0.0447734 5.48753 0.26607 5.26624C0.487368 5.04494 0.846161 5.04494 1.06746 5.26624L3.9823 8.18108L10.9157 0.617353C11.1272 0.386652 11.4856 0.371067 11.7163 0.582543Z"
                                fill="white"
                            />
                        </svg>
                    )}
                </div>
                <label htmlFor={id} className={labelClassName || 'select-none mb-0'} onClick={onChange}>
                    {label}
                </label>
            </div>
        </div>
    );
};

export default Checkbox;
