import React, { useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';

import { ReactComponent as PdfIcon } from '../../assets/icons/pdf-icon.svg';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../containers/ScreenContainers';
import { Button } from '../buttons';
import Spinner from '../spinner';

const PDFPreview = ({ url = '', onPdfClick, pdfTitle, mobile }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [numPages, setNumPages] = useState([]);
    const [scale, setScale] = useState(1.0);
    const [pageNumber, setPageNumber] = useState(1);

    const handleZoomIn = () => {
        setScale((prevScale) => prevScale + 0.25);
    };

    const handleZoomOut = () => {
        setScale((prevScale) => Math.max(prevScale - 0.25, 0.25));
    };

    return (
        <div className="relative">
            <FlexRowSpaceBetween className="border-b py-[2rem] px-[2rem] gap-[1rem] md:gap-[3rem] shadow-md">
                <FlexCentredRow className="gap-4">
                    <PdfIcon />
                    <Text weight="600" size="1.9rem" lineHeight="2.8rem">
                        {pdfTitle}
                    </Text>
                </FlexCentredRow>

                <FlexCentredRow className="gap-[0.3rem] md:gap-[1rem]">
                    <Button weight="600" bgColor="[#E7E2E2]">
                        {pageNumber}
                    </Button>
                    <Text>/</Text>
                    <Text weight="600">{numPages}</Text>
                </FlexCentredRow>

                <FlexCentredRow className="gap-[0.3rem] md:gap-[1rem]">
                    <Button size="2rem" weight="600" onClick={handleZoomOut} disabled={scale >= 2.0}>
                        -
                    </Button>
                    <Button size="2rem" weight="600" onClick={handleZoomIn} disabled={scale >= 0.8}>
                        +
                    </Button>
                </FlexCentredRow>
            </FlexRowSpaceBetween>
            <div>
                <Document
                    className="flex items-center justify-center flex-col"
                    onClick={onPdfClick}
                    file={url}
                    onLoadSuccess={(pdf) => {
                        setNumPages(pdf.numPages);
                        setPageNumber(1);
                    }}
                    loading={<Spinner />}
                >
                    {Array.from(new Array(numPages), (el, index) => index + 1).map((page) => (
                        <Page
                            key={`page_${page}`}
                            pageNumber={page}
                            scale={scale}
                            width={mobile ? 350 : 850}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                        />
                    ))}
                </Document>
            </div>
        </div>
    );
};

export default PDFPreview;
