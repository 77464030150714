import React from 'react';

import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search2.svg';

const Container = styled.div`
    position: relative;
    height: 40px;
    width: fit-content;
`;

const Input = styled.input`
    width: ${({ width }) => width || '28.3rem'};
    border: 1px solid #e5e7eb;
    color: #1f2937;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 8px 16px;
    outline: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 100%;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 20px;
`;

const SearchInput = ({ placeholder, value, onClick, onChange, width, className }) => {
    return (
        <Container className={className}>
            <Input width={width} onChange={onChange} placeholder={placeholder || 'Search'} />
            {!value?.length > 0 ? (
                <IconWrapper>
                    <SearchIcon />
                </IconWrapper>
            ) : (
                <IconWrapper>
                    <CloseIcon />
                </IconWrapper>
            )}
        </Container>
    );
};

export default SearchInput;
